import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import { userIsMemberOfProject } from "@/app/helpers";
import ProfilesChangerLine from "./ProfilesChangerLine.vue";
import VersionSelect from "@/views/common/VersionSelect.vue";
import IconDownload from "@/icons/download-solid.svg";
import Profile from "@/views/common/Profile.vue";

// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");

function formatFilters(filters) {
    const profile = filters.profile;
    const vcrId = profile && !profile.redmine ? profile.id : null;
    const redmineId = profile && profile.redmine ? profile.id : null;
    return {
        projectId: filters.project?.id,
        versionId: filters.version?.id,
        vcrId,
        redmineId,
        issueId: filters.issue?.id,
        resourceId: filters.resource?.id,
        from: filters.from || null,
        to: filters.to || null,
    };
}

function defaultFilters() {
    return {
        project: null,
        version: null,
        profile: null,
        issue: null,
        resource: null,
        from: '',
        to: '',
    };
};

export default {
    name: "ProfilesChanger",
    components: {
        Multiselect,
        FlatPickr,
        ProfilesChangerLine,
        TimeModal,
        IconDownload,
        VersionSelect,
        Profile,
    },
    data() {
        return {
            filters: defaultFilters(),
            dateConfig: {
                locale: French,
            },
            versions: [],
            vcrs: [],
            issues: [],
            timeEntries: [],
            timeEntryId: null,
            selectedTimeEntries: [],
            selectedAll: false,
            selectedProfile: null,
        };
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            user: 'Resource/auth/user',
            resources: 'Resource/list/resources',
            activities: 'Enumeration/list/activities',
        }),
        projects() {
            const projects = this.$store.getters['Project/list/projects'];
            if (this.user.admin) return projects;
            return projects.filter((project) =>
                userIsMemberOfProject(this.user, project)
            );
        },
        canList() {
            return !!this.filters.project && !!this.filters.version;
        },
        redmineProfiles() {
            return this.activities.map(act => ({
                id: act.id,
                name: act.name,
                redmine: true,
            }));
        },
        filterableProfiles() {
            const outOfVersionProfile = { id: -1, name: 'Hors version', mismatch: true };
            return this.vcrs.concat([outOfVersionProfile], this.redmineProfiles);
        },
        changeableProfiles() {
            return this.vcrs.concat(this.redmineProfiles);
        },
        selectedDays() {
            if (!this.selectedTimeEntries.length) return 0;
            const selectedHours = this.selectedTimeEntries.reduce((agg, entry) => {
                return agg += entry.hours;
            }, 0);
            return Math.round(selectedHours / 8 * 10) / 10;
        },
    },
    methods: {
        refreshVersions() {
            this.$store.dispatch('Issue/list/getVersions', this.filters.project.id).then((versions) => {
                this.versions = versions;
            });
        },
        refreshIssues() {
            if (!this.filters.version) return (this.issues = []);
            const payload = {
                projectId: this.filters.project.id,
                versionIds: [this.filters.version.id],
                includeClosed: true,
            };
            this.$store.dispatch('Issue/list/getList', payload).then((issues) => {
                this.issues = issues;
            })
        },
        refreshVcrs() {
            this.$store.dispatch('KPI/list/getVcrs', this.filters.version.id).then((vcrs) => {
                this.vcrs = vcrs;
            });
        },
        refreshTimeEntries() {
            this.selectedAll = false;
            this.selectedTimeEntries = [];
            this.selectedProfile = null;
            if (!this.canList) return (this.timeEntries = []);
            this.$store.dispatch('TimeEntry/list/getListForProfilesChanger', formatFilters(this.filters)).then(timeEntries => {
                this.timeEntries = timeEntries;
            });
        },
        resetFilters() {
            this.filters = defaultFilters();
            this.versions = [];
        },
        selectAll() {
            this.selectedTimeEntries = this.selectedAll ? [...this.timeEntries] : [];
        },
        updateProfiles() {
            if (!this.selectedTimeEntries.length || !this.selectedProfile) return;
            if (!window.confirm('Êtes-vous sûr de vouloir changer le profils de ces ' + this.selectedTimeEntries.length + ' saisies ?')) return;
            const payload:any = {
                timeEntryIds: this.selectedTimeEntries.map(entry => entry.id),
            };
            const profile = this.selectedProfile;
            if (profile.redmine) payload.redmineId = profile.id;
            else payload.vcrId = profile.id;
            this.$store.dispatch('TimeEntry/list/updateProfiles', payload).then(() => {
                this.refreshTimeEntries();
                this.refreshVcrs();
            });
        },
    },
    watch: {
        'filters.project'() {
            this.filters.version = null;
            if (!this.filters.project) return (this.versions = []);
            this.refreshVersions();
        },
        'filters.version'() {
            if (this.filters.profile && !this.filters.profile.mismatch && !this.filters.profile.redmine) {
                this.filters.profile = null;
            }
            this.filters.issue = null;
            if (!this.filters.version) {
                this.vcrs = [];
                this.issues = [];
                return;
            };
            this.refreshVcrs();
            this.refreshIssues();
        },
        filters: {
            deep: true,
            handler() {
                this.refreshTimeEntries();
            }
        },
    },
    mounted() {
        this.$store.dispatch('Project/list/getList');
        this.$store.dispatch('Enumeration/list/getActivities');
    },
};
