<template>
  <div>
    <span v-if="profile.redmine" class="opacity7">Redmine </span>
    <span :class="{ 'text-danger': profile.mismatch }">{{
      profile.profile || profile.name
    }}</span>
    <span v-if="hasRemaining" class="opacity7">
      ({{ Math.round((profile.remaining_hours / 8) * 10) / 10 }}j.
      restant)</span
    >
  </div>
</template>

<script>
export default {
  props: {
    profile: {},
  },
  computed: {
    hasRemaining() {
      return typeof this.profile.remaining_hours !== "undefined";
    },
  },
};
</script>
