<template>
  <tr>
    <td><Checkbox v-model="selected" :value="value" :id="'time_entry_' + value.id" /></td>
    <td>
      <label :for="'time_entry_' + value.id" class="mb-0">
        <Profile :profile="value" />
      </label>
    </td>
    <td class="text-right">{{ value.hours }}h</td>
    <td>
      <span
        @click="$emit('openEntry', value.id)"
        class="
          text_link text-nowrap
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <IconCalendar class="mr-1" />
        {{ value.spent_on }}
      </span>
    </td>
    <td>
      <clink :to="href" :text="true" tabindex="-1">{{ value.issue }}</clink>
    </td>
    <td>{{ value.resource }}</td>
  </tr>
</template>

<script>
import IconCalendar from "@/icons/calendar-solid.svg";
import Profile from "@/views/common/Profile.vue";

export default {
  props: ["value", "values"],
  model: { prop: "values" },
  components: {
    IconCalendar,
    Profile,
  },
  computed: {
    selected: {
      get() {
        return this.values;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    href() {
      return {
        name: "tree",
        params: {
          type: "issue",
          id: this.value.issue_id,
        },
      };
    },
  },
};
</script>
